export default {
    data() {
        return {
            detachedH1: null
        };
    },
    props: {
        showCustomerService: {
            type: Boolean,
            default: true
        },
        showTopBar: Boolean
    },
    computed: {
        headerVariant() {
            return this.$store.state.globalMeta.headerVariant;
        },
        isDefaultHeader() {
            return this.headerVariant === 'default' || !this.headerVariant;
        },
        isCooperationHeader() {
            return this.headerVariant === 'cooperation';
        },
        platformLogoUrl() {
            return this.$store.state.globalMeta.platformLogoUrl;
        },
        platformLogoHref() {
            return this.$store.state.globalMeta.platformLogoHref;
        },
        headingTag() {
            return this.$route.name === 'index' ? 'h1' : 'span';
        },
        showCoopText() {
            return !!this.$t('Header.coopText');
        }
    },
    methods: {
        preventDuplicateH1() {
            document.querySelectorAll('h1').forEach(h1Element => {
                const elementToCheck = h1Element.closest('.media-only');
                if (elementToCheck && getComputedStyle(elementToCheck).display === 'none') {
                    h1Element.remove();
                }
            });
        }
    },
    mounted() {
        this.preventDuplicateH1();
    }
};
